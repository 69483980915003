<template>
	<div class="choseMain-main">
		<div class="content">
			<div class="main_box">
				<p class="title1">欢迎进入</p>
				<el-row>
					<el-col :span="12">
						<p class="title2">管理系统</p>
					</el-col>
					<el-col :span="12">
						<p class="title3 tr">请选择主体类型</p>
					</el-col>
				</el-row>

				<div class="row">
					<div class="row_box"
                         v-for="(i, index) in mainData">
						<div class="icon">
							<img class="icon_box"
								 :src="i.iconUrl">
						</div>
						<div class="top">
							<!-- 业主/建设单位 -->
                            {{i.sname}}
						</div>
						<div class="info">
							<div>
								<!-- 执行国家基本建设计划，组织、督促基本建设工作，支配、使用基本建设投资的基层单位。 -->
							</div>

							<div class="btn"
                                 @click="enter(i)">
								进入
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <div class="foot tc">
            <p>
                工作时间: 9:00-12:00  13:30-17:30     联系电话∶400-800-6335  技术支持∶安徽本末数据科技有限公司
            </p>
            <p>
                皖公网安备 34019102000906号    ICP证: 皖ICP备18000629号-4
            </p>
        </div>
	</div>
</template>

<script>
import { setItem } from '@/utils/storage'

export default {
	name: 'choseMain',
	data() {
		return {
			mainData: []
		}
	},
	methods: {
        enter(data) {
            setItem('limitId', data.id)
            this.$router.push({ path: '/Select' })
        }
	},
	created() {
        if (this.$route.params.data) {
            this.mainData = this.$route.params.data.limitList
			this.mainData.map(i => {
				i.iconUrl = 'http://112.31.81.178:11002/1201' + i.file.file_directory + i.file.file_orign_name
			})
        } else {
            this.$router.go(-1)
        }
	}
}
</script>


<style lang="less">
.choseMain-main {
	height: 100%;
	.content {
		width: 100%;
		background: url(../../assets/images/choseMain_bg.jpg) no-repeat;
		background-size: 100% 100%;
		height: calc(~'100% - 220px');
		position: relative;
		.main_box {
			width: 90%;
			position: absolute;
			color: #202020;
			left: 50%;
			top: 50%;
			-webkit-transform: translateY(-50%) translateX(-50%);
			transform: translateY(-50%) translateX(-50%);
			.title1 {
				font-size: 20px;
				line-height: 50px;
			}
			.title2 {
				font-size: 24px;
				line-height: 50px;
			}
			.title3 {
				font-size: 20px;
				color: #666;
				line-height: 50px;
			}
			.row {
				display: flex;
				justify-content: space-between;
				margin-top: 20px;
				.row_box {
					width: 287px;
					position: relative;
					background: white;
					cursor: pointer;
                    border-radius: 3px;
					.top {
						height: 160px;
						padding-top: 52px;
						text-align: center;
						font-size: 20px;
						background: #f8f9fd;
					}
					.icon {
						border-radius: 50%;
						border: 1px solid #f8f9fd;
						position: absolute;
						top: 110px;
						left: 50%;
						-webkit-transform: translateX(-50%);
						transform: translateX(-50%);
						width: 100px;
						height: 100px;
						background: white;
						.icon_box {
							display: block;
							width: 60px;
							height: 60px;
							margin-top: 20px;
							margin-left: 20px;
						}
					}
					.info {
						padding: 70px 32px;
						font-size: 14px;
						color: #666;
						position: relative;
						height: 340px;
						.btn {
							width: 180px;
							height: 32px;
							position: absolute;
							bottom: 32px;
							left: 50%;
							-webkit-transform: translateX(-50%);
							transform: translateX(-50%);
							background: linear-gradient(
								90deg,
								#32d3cb 0%,
								#269bef 100%
							);
							border-radius: 3px;
							color: white;
							font-size: 14px;
							text-align: center;
							line-height: 32px;
						}
					}
				}

                .row_box:hover {
                    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
                    .top {
                        color: white;
                        background: linear-gradient(360deg, #32D3CB 0%, #269BEF 100%);
                        border-top-left-radius: 3px;
                        border-top-right-radius: 3px;
                    }
                    .icon {
                        border: 1px solid #60ADF9;
                    }
                }

				.first {
					.icon_box {
						background: url(../../assets/images/jsdw.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.first:hover {
					.icon_box {
						background: url(../../assets/images/jsdw1.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.second {
					.icon_box {
						background: url(../../assets/images/sjgl.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.second:hover {
					.icon_box {
						background: url(../../assets/images/sjgl1.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.third {
					.icon_box {
						background: url(../../assets/images/xsdw.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.third:hover {
					.icon_box {
						background: url(../../assets/images/xsdw1.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}
			}
		}
	}

    .foot {
        height: 220px;
        padding-top: 30px;
        p {
            font-size: 14px;
            color: #999;
            margin-bottom: 13px;
        }
    }
}
</style>

